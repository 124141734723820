import axios from 'axios'
import { Message } from 'view-ui-plus'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router/index.js'

import useUserStore from '@/store/modules/user' //引入tabsView

// 设置axios跨域访问
axios.defaults.withCredentials = false // 设置cross跨域 并设置访问权限 允许跨域携带cookie信息
axios.defaults.crossDomain = true // 设置axios跨域的配置

// 创建axios实例
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API, //基础路径
  timeout: 3 * 60 * 1000 // request timeout
})

// 请求拦截
service.interceptors.request.use(
  (config) => {
    // 发送请求之前做什么操作
    if (config.url !== '/user/login') {
      // 检查是否是登录接口
      config.headers['Authorization'] = getToken()
    } else {
      // 如果是登录接口，不传递 token
      delete config.headers['Authorization']
    }
    if (config.itemType == 'xcx') {
      config.baseURL = config.baseURL.replace('/platform', '')
      config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('xcxToken')
    }
    if (['property', 'hetong'].includes(config.itemType)) {
      config.baseURL = config.baseURL.replace('/platform', '')
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// 响应拦截

service.interceptors.response.use(
  (response) => {
    const { status, data } = response
    if (status == 200) {
      return Promise.resolve(data)
    } else {
      return Promise.reject(new Error(res.message || 'Error'))
    }
  },
  (error) => {
    // Message.error({
    //   content: error.message
    // })
    const response = error.response
    // 检查是否收到 401 错误清除令牌
    if (response && response.status === 401) {
      Message.error({
        content: response.data.detail
      })
      router.replace(`/admin/login`)
      // window.router.replace(`/admin/login`)
      removeToken()
      localStorage.clear()
      sessionStorage.clear()

      //400错误
    } else if (response && response.status === 400) {
      Message.error({
        content: response.data.detail,
        duration: 8
      })
    } else {
      let message = error.response.data.detail || '请求出错了,请稍后再试'
      if (error.response.data.detail instanceof Array) {
        message = error.response.data.detail[0].msg
      }
      // 其他错误处理
      Message.error({
        content: message,
        duration: 8
      })
    }

    return Promise.reject(error)
  }
)

export default service
