import { defineStore } from 'pinia'
const companyList = defineStore('companyList', {
  state: () => ({
    companyList: []
  }),
  persist: {
    storage: localStorage,
    paths: ['companyList'], //只缓存这四个数据
    debug: true //是否开启debug模式
  },
  actions: {
    setCompanyList(list) {
      this.companyList = list
    },
    removeCompanyList() {
      this.companyList = []
    }
  }
})
export default companyList
